.contactotitlebox {
    font-family: 'MiFuente';
    padding: 2rem 0;
    text-align: center;
}

.contactobox {
    background-color: rgb(231, 231, 231);
    padding-bottom: 2rem;
}

h2 {
    font-size: 1.7rem !important;
    font-weight: bold;
}

.contactotextobox {
    width: 60%;
    margin: 0 auto; /* Centrar el contenedor en la página */
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-family: 'MiFuente' !important
}

.svgcontacto {
    width: 4rem;
    height: 4rem;
    transition: transform 0.3s ease;
}

.svgcontacto:hover {
    transform: scale(1.2);
}

.textocontacto {
    text-decoration: none;
    color: #c18d43;
}

.textocontacto:hover {
    text-decoration: underline;
}

.cajaredes {
    display: flex;
    justify-content: space-between;
}

.infocontact{
    font-size: 1.3rem;
}

@media screen and (max-width: 834px) {
    .contactotextobox {
        width: 95%;
        flex-direction: column;
        gap: 2.9rem;
    }
    .cajaredes {
        display: flex;
        justify-content:space-evenly;
        padding-top: 0.6rem;
    }

    .hcontact {
        font-size: 1.55rem !important;
    }
    .infocontact{
        font-size: 1.2rem;
    }
  }