.titlebox {
    font-family: 'MiFuente';
    padding: 2rem 0;
    padding-bottom: 1rem;
    text-align: center;
}

.somosbox {
    background-color: whitesmoke;
    padding-bottom: 2rem;
}

p {
    font-family: 'MiFuente';
}

.textobox {
    width: 60%;
    margin: 0 auto; /* Centrar el contenedor en la página */
    text-align: justify;
}

.descripcion {
    text-align: justify;
}

b{
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
    .textobox {
        width: 80%;
    }
  }