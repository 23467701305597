.footerbox {
    background-color: #212529;
    color: #c18d43;
    font-family: 'MiFuente';
    bottom: 0;
    width: 100%;
    text-align: center;
    
}

