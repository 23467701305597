  .product--image {
    width: 100%;
    height: 17rem;

    object-fit: fill;
  }
  
  .price {
    color: grey;
    font-size: 22px;
  }
  
  .card {
    text-align: center;
    cursor: pointer;
    font-size: 4rem;
    width: 100%;
  }

  .react-multi-carousel-track {
    gap: 0;
  }  

.anime {
    opacity: 1;
}

.anime:hover{
    transition: opacity 0.3s ease;
    opacity: 0.6;
}

.information{
  font-size: 1rem;
  color: gray;
}

.detailtitle{
  font-size: 1.2rem !important;
  font-weight: bold;
  margin-bottom: 0 !important;
  color: black !important;
}

@media screen and (max-width: 768px) {
    .card {
        width: 100%;
      }
  }