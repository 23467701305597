.commsec {
    padding-top: 1rem;
    margin: 2rem auto;
}

.commsec-title {
    font-size: 1.5em;
}

.commsec-intro {
    font-size: large;
}

.scroll {
    height:20rem;
    overflow: scroll;
}
